import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { ConnectBlog } from '@silkpwa/module/react-component/connect-blog';
import { Loading } from 'ui/component/checkout/steps/loading';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import BlogViewContent from './blog-view-content';

const BlogViewPage = () => {
    const storeConfig = useConfig();
    if (!storeConfig?.extension_attributes?.cw_blog_enable) {
        window.location.href = window.location.origin;
    }
    return (
        <ApolloProvider client={GraphQlClient}>
            <ConnectBlog fallback={Loading}>
                {(blogId: number) => <BlogViewContent blogId={blogId} />}
            </ConnectBlog>
        </ApolloProvider>
    );
};

export default BlogViewPage;

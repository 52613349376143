import React from 'react';

import styles from './style.css';

interface BannerProps {
    DeskTopBanner: string;
    MobileBanner: string;
    alt: string;
}

const HeroBanner: React.FC<BannerProps> = ({
    DeskTopBanner,
    MobileBanner,
    alt,
}) => (
    <div>
        <div className={styles.desktopBannerCnt}>
            <img
                src={DeskTopBanner}
                alt={alt}
                className={styles.bannerImgCnt}
            />
        </div>
        <div className={styles.mobileBannerCnt}>
            <img
                src={MobileBanner}
                alt={alt}
                className={styles.bannerImgCnt}
            />
        </div>
    </div>
);

export default HeroBanner;

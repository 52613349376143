import React from 'react';
import { useQuery } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { GET_SINGLE_BLOG_POST } from 'graphql/blog/blog-post-view';
import ArrowBack from 'assets/images/arrow-back_red.svg';
import SocialShare from 'ui/component/social-share/social-share';
import { LoadingImage } from 'ui/component/loading-image';
import { TPostResponse } from 'ui/util/type-helper';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import fbBlackBg from 'assets/images/fb-black-bg.svg';
import twitterBlackBg from 'assets/images/twitter-black-bg.svg';
import navigateSideArrow from 'assets/images/navigate-side-arrow.svg';
import HeroBanner from './hero-banner/hero-banner';
import { CommentForm } from './comment-form/comment-form';

import styles from './style.css';

const BlogViewContent = ({ blogId }: { blogId: number }) => {
    const storeConfig = useConfig();
    const storeId = storeConfig.store_config.current_store.id;
    const reCaptcWebsiteKey = storeConfig.extension_attributes?.cw_enabled_recaptcha_for_question &&
        storeConfig.extension_attributes?.recaptcha_public_key;
    const pageTitle = storeConfig?.design_config?.default_page_title ?? '';

    const {
        loading, data,
    } = useQuery<TPostResponse>(GET_SINGLE_BLOG_POST, {
        variables: {
            storeId,
            postId: blogId,
        },
    });

    const t = usePhraseTranslater();

    const updateMetaTag = (key: 'name' | 'property', name: string, content: string) => {
        let metaTag = document.querySelector(`meta[${key}='${name}']`);
        if (metaTag) {
            metaTag.setAttribute('content', content);
        } else {
            metaTag = document.createElement('meta');
            metaTag.setAttribute(key, name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
        }
    };

    if (loading) {
        return (
            <div className={styles.smallLoadingImage}>
                <LoadingImage />
            </div>
        );
    }

    const postData = data?.getPost;

    if (data?.getPost?.meta_description) {
        updateMetaTag('name', 'description', data?.getPost?.meta_description);
    }
    if (data?.getPost?.meta_keywords) {
        updateMetaTag('name', 'keywords', data?.getPost?.meta_keywords);
    }
    if (data?.getPost?.meta_title) {
        updateMetaTag('name', 'title', data?.getPost?.meta_title);
    }
    if (data?.getPost?.meta_robots) {
        updateMetaTag('name', 'robots', data?.getPost?.meta_robots);
    }

    if (postData?.desktop_banner_url) {
        updateMetaTag('property', 'og:image', postData?.desktop_banner_url);
        updateMetaTag('name', 'twitter:image', postData?.desktop_banner_url);
    }
    if (postData?.title) {
        updateMetaTag('property', 'og:title', postData?.title);
        updateMetaTag('name', 'twitter:title', postData?.title);
    }

    return (
        <>
            <DocumentTitle>{postData?.title}</DocumentTitle>
            <div className={styles.mainCnt}>
                {postData?.desktop_banner_url && (
                    <HeroBanner
                        DeskTopBanner={postData?.desktop_banner_url}
                        MobileBanner={postData?.mobile_banner_url
                            ? postData?.mobile_banner_url
                            : postData?.desktop_banner_url
                        }
                        alt={postData?.title}
                    />
                )}
                <div className={styles.contentCnt}>
                    <div className={classes(styles.authorCnt, styles.pd15)}>
                        <div className={styles.createdCnt}>
                            {postData?.created_at}
                        </div>
                        <div>
                            <SocialShare
                                fbShareLink={postData?.fb_share_url ? postData.fb_share_url : ''}
                                twitterShareLink={postData?.twitter_share_url ? postData.twitter_share_url : ''}
                                fbImg={fbBlackBg}
                                twitterImg={twitterBlackBg}
                            />
                        </div>
                    </div>
                    <div className={classes(styles.descCnt)}>
                        <div className={styles.titleCnt}>
                            {postData?.title}
                        </div>
                        {postData?.content && (
                            /* eslint-disable react/no-danger */
                            <div
                                dangerouslySetInnerHTML={{ __html: postData?.content }}
                            />
                        )}
                    </div>

                    <div className={classes(styles.descBottomCnt, styles.pd15)}>
                        <div className={styles.backLinkCnt}>
                            {postData?.redirect_back_url && (
                                <AccessibleButton
                                    tag="a"
                                    action={() => {
                                        if (postData?.redirect_back_url) {
                                            window.location.assign(`${window.location.origin}/${postData.redirect_back_url}`);
                                        }
                                    }}
                                    className={styles.linkCnt}
                                >
                                    <img
                                        src={ArrowBack}
                                        alt="back-arrow"
                                        className={styles.backImgCnt}
                                    />
                                    <span>{t(`Back to ${pageTitle} Insider`)}</span>
                                </AccessibleButton>
                            )}
                        </div>
                        <div>
                            {postData?.navigation.prev && (
                                <AccessibleButton
                                    tag="a"
                                    action={() => {
                                        if (postData?.navigation.prev) {
                                            window.location.assign(`${window.location.origin}/${postData?.navigation.prev}`);
                                        }
                                    }}
                                    className={styles.linkCnt}
                                >
                                    <span>{t('Previous')}</span>
                                </AccessibleButton>
                            )}
                            {postData?.navigation.prev && postData?.navigation.next && '  |  '}
                            {postData?.navigation.next && (
                                <AccessibleButton
                                    tag="a"
                                    action={() => {
                                        if (postData?.navigation.next) {
                                            window.location.assign(`${window.location.origin}/${postData?.navigation.next}`);
                                        }
                                    }}
                                    className={styles.linkCnt}
                                >
                                    <span>{t('Next')}</span>
                                </AccessibleButton>
                            )}
                        </div>
                    </div>

                    {postData?.related_posts && postData?.related_posts.length > 0 && (
                        <div className={classes(styles.relatedBlogCnt, styles.pd15)}>
                            <div className={styles.titleCnt}>
                                <span>{t('You Might Also Like')}</span>
                            </div>
                            <div className={styles.relatedCardCnt}>
                                {postData.related_posts.map(post => (
                                    <div className={classes(styles.singleCardCnt, {
                                            [styles.spaceAround]: postData.related_posts.length < 3,
                                        })}
                                    >
                                        <AccessibleButton
                                            tag="a"
                                            action={() => {
                                                if (post.url_key_path) {
                                                    window.location.assign(`${window.location.origin}/${post.url_key_path}`);
                                                }
                                            }}
                                            className={styles.blogLinkEle}
                                        >
                                            <img
                                                src={post?.desktop_banner_url}
                                                alt={post.title}
                                                className={styles.desktopImgEle}
                                            />
                                            <img
                                                src={post?.mobile_banner_url}
                                                alt={post.title}
                                                className={styles.mobileImgEle}
                                            />
                                        </AccessibleButton>
                                        <div className={styles.relatedCardBelowCnt}>
                                            <span className={styles.relatedTitleCnt}>{post.title}</span>
                                            {post.url_key_path && (
                                                <div className={styles.navigateArrowCnt}>
                                                    <AccessibleButton
                                                        tag="a"
                                                        action={() => {
                                                            if (post.url_key_path) {
                                                                window.location.assign(`${window.location.origin}/${post.url_key_path}`);
                                                            }
                                                        }}
                                                        className={styles.linkCnt}
                                                    >
                                                        <img
                                                            src={navigateSideArrow}
                                                            alt={post.title}
                                                            className={styles.navigateArrowImgElement}
                                                        />
                                                    </AccessibleButton>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                { postData?.entity_id && postData?.allow_comment === 1 && (
                    <div className={styles.commentCnt}>
                        <CommentForm postId={postData?.entity_id} storeId={storeId} recaptchaKey={reCaptcWebsiteKey} />
                    </div>
                )}
            </div>
        </>
    );
};

export default BlogViewContent;

import React, {
    ChangeEvent,
    FocusEvent,
    FormEvent,
    useRef,
    useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import Input from 'ui/component/input/input';
import Button from 'ui/component/button/button';
import ReCAPTCHA from 'react-google-recaptcha';
import { sanitizeInput, validationHandler } from 'ui/util/validator-helper';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useContainerHook } from '@silkpwa/redux';
import {
    CreateCommentInputGql,
    CreateCommentResponseGql,
    ICommentData,
} from 'ui/util/type-helper';
import { CREATE_COMMENT_MUTATION } from 'graphql/blog/mutation/post-user-comment';

import styles from './style.css';

interface CFProps {
    account: AccountState;
    postId: number;
    storeId: number;
    recaptchaKey?: string|false;
}

const CommentForm: React.FC<CFProps> = (
    {
        account,
        postId,
        storeId,
        recaptchaKey,
    },
) => {
    const [addingComment, setAddingComment] = useState(false);

    const [commentData, setCommentData] = useState({
        postId,
        storeId,
        comment: '',
        email: '',
        author: '',
        website: '',
    });

    const [errors, setErrors] = useState({
        comment: {
            message: '',
        },
        email: {
            message: '',
        },
        author: {
            message: '',
        },
        submit: {
            message: '',
        },
    });
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [
        createComment,
    ] = useMutation<CreateCommentResponseGql, CreateCommentInputGql>(CREATE_COMMENT_MUTATION);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const enqueueNotification = useContainerHook<() => any>('useEnqueueNotification');

    const t = usePhraseTranslater();

    const setErrorData = (name: string, message: string) => {
        setErrors(prevState => ({
            ...prevState,
            [name]: {
                message,
            },
        }));
    };

    const handleSubmit = async (recaptchaToken: string|null) => {
        let error = false;
        setAddingComment(true);
        Object.keys(commentData).forEach((field) => {
            if (commentData[field as keyof ICommentData] === '') {
                if (field !== 'website') {
                    error = true;
                }
            }
        });

        if (!error) {
            const requestBody: CreateCommentInputGql = {
                author: commentData.author,
                email: commentData.email,
                comment: commentData.comment,
                storeId: commentData.storeId,
                postId: commentData.postId,
                website: commentData.website,
                customerId: account.isLoggedIn ? account?.info?.id : undefined,
            };
            try {
                const context = recaptchaToken ? {
                    headers: {
                        'X-ReCaptcha': recaptchaToken,
                    },
                } : {};

                const { errors, data } = await createComment({
                    context,
                    variables: requestBody,
                });

                setCommentData({
                    postId,
                    storeId,
                    comment: '',
                    email: '',
                    author: '',
                    website: '',
                });

                if (errors) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    enqueueNotification({
                        type: 'primary',
                        message: t('Some issue occur while posting. Please try after sometime!.'),
                        time: 5000,
                    });
                }

                if (data?.createComment.comment) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    enqueueNotification({
                        type: 'primary',
                        message: t('Yor post submitted successfully!.'),
                        time: 5000,
                    });
                }

                setAddingComment(false);
            } catch (e) {
                setAddingComment(false);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                enqueueNotification({
                    type: 'primary',
                    message: t('Some issue occur while posting. Please try after sometime!.'),
                    time: 5000,
                });
            }
        } else {
            setAddingComment(false);
            setErrorData('submit', t('Please fill in all fields.'));
        }
    };

    const triggerFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (recaptchaRef.current) {
            recaptchaRef.current.execute();
        } else {
            handleSubmit(null);
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const sanitizedValue = sanitizeInput(value);
        setCommentData(prevData => ({
            ...prevData,
            [name]: sanitizedValue,
        }));
    };

    const handleOnBlur = (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => {
        validationHandler(event, setErrorData);
    };

    return (
        <>
            <div className={styles.commentFrmWidth}>
                <div className={styles.commentTitleMainCnt}>
                    <div className={classes(styles.titleCnt, styles.titleFormCnt)}>
                        <span>{t('We’d Love To Hear From You')}</span>
                    </div>
                    <span>{t('Your email address will not be published.  Required fields marked*')}</span>
                </div>
                <div className={styles.commentForm}>
                    <form className={styles.form} onSubmit={triggerFormSubmit}>
                        <Input
                            id="comment"
                            name="comment"
                            type="textarea"
                            label={t('Comment')}
                            value={commentData.comment}
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                            error={errors.comment}
                            labelClassName={styles.labelStyles}
                            required
                        />
                        <Input
                            id="author"
                            name="author"
                            type="text"
                            label={t('Name')}
                            value={commentData.author}
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                            error={errors.author}
                            labelClassName={styles.labelStyles}
                            required
                        />
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            label={t('Email')}
                            value={commentData.email}
                            onChange={handleInputChange}
                            onBlur={handleOnBlur}
                            error={errors.email}
                            labelClassName={styles.labelStyles}
                            required
                        />
                        <Input
                            id="website"
                            name="website"
                            type="text"
                            label={t('Website')}
                            value={commentData.website}
                            onChange={handleInputChange}
                            labelClassName={styles.labelStyles}
                            onBlur={handleOnBlur}
                        />
                        <div className={styles.textAlignLeft}>
                            <Button
                                type="submit"
                                disabled={addingComment}
                                className={styles.submitBtnCnt}
                            >
                                { addingComment
                                    ? <span className={styles.submittingBtn}>{t('Submitting')}</span>
                                    : <span className={styles.submitBtn}>{t('Post Comment')}</span>
                                }
                            </Button>
                        </div>
                        {recaptchaKey && (
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={recaptchaKey}
                                size="invisible"
                                isolated
                                onChange={(token) => {
                                    handleSubmit(token);
                                }}
                            />
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

const connectCommentAccount = connectAccount(CommentForm);

export { connectCommentAccount as CommentForm };

import { connect } from '@silkpwa/redux';

const Blog = ({ blog, fallback, children }) => {
    if (blog === -1) return fallback || null;
    return children(blog);
};

export const ConnectBlog = connect({
    using: ['ecommerceBlog'],
    mapStateToProps: blog => state => ({
        blog: Number(blog.selectors.getBlogId(state)),
    }),
})(Blog);

import { gql, TypedDocumentNode } from '@apollo/client';
import { CreateCommentInputGql, CreateCommentResponseGql } from 'ui/util/type-helper';

export const CREATE_COMMENT_MUTATION: TypedDocumentNode<CreateCommentResponseGql, CreateCommentInputGql> = gql`
    mutation CreateComment(
        $author: String!,
        $email: String!,
        $comment: String!,
        $postId: Int!,
        $storeId: Int!,
        $website: String,
        $customerId: Int
    ) {
        createComment(
            author: $author,
            email: $email,
            comment: $comment,
            store_id: $storeId,
            post_id: $postId,
            website: $website,
            customer_id: $customerId
        ) {
            author
            email
            comment
            store_id
            customer_id
            created_at
        }
    }
`;

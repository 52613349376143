import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_SINGLE_BLOG_POST: TypedDocumentNode = gql`
    query GET_BLOG_POST_VIEW(
        $storeId: Int!
        $postId: Int!
    ){
        getPost(store_id: $storeId, post_id: $postId) {
            entity_id
            store_id
            title
            content
            short_description
            created_at
            desktop_banner_url
            mobile_banner_url
            is_add_post
            is_featured_post
            allow_comment
            url_key_path
            fb_share_url
            twitter_share_url
            meta_title
            meta_description
            meta_keywords
            meta_robots
            author
            tags {
                tag_id
                name
            }
            navigation {
                next
                prev
            }
            related_posts {
                entity_id
                title
                url_key_path
                desktop_banner_url
                mobile_banner_url
            }
            redirect_back_url
        }
    }
`;
